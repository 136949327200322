export interface ICookieOptions {
  "expires"?: Date | string;
  "path"?: string;
  "domain"?: string;
  "secure"?: boolean;
  "max-age"?: number;
}

export const Cookie = {
  set: function (
    name: string,
    value: string,
    options: ICookieOptions = {
      "max-age": APP_COOKIE_MAX_AGE_YEAR,
    },
  ) {
    const props = {
      path: "/",
      ...options,
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie =
      encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (const optionKey in props) {
      updatedCookie += "; " + optionKey;
      const optionValue = props[optionKey as keyof ICookieOptions];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }

    document.cookie = updatedCookie;
  },

  getAll: function () {
    if (typeof document === "undefined") {
      return null;
    }
    const cookie: any = {};
    document.cookie.split(";").forEach((el) => {
      const [k, v] = el.split("=");
      cookie[k.trim()] = v;
    });
    return cookie;
  },

  get: function (name: string, isObject?: boolean, domain?: string) {
    if (typeof document === "undefined") {
      return null;
    }

    const value = this.getAll()[name];
    if (!value) {
      return null;
    }

    if (isObject) {
      try {
        const cookieObject = JSON.parse(decodeURIComponent(value));
        if (domain) {
          if (cookieObject.domain !== domain) {
            return null;
          }
        }
        return cookieObject;
      } catch {
        return null;
      }
    }

    return value;
  },

  delete: function (name: string, domain?: string) {
    if (typeof document === "undefined") {
      return null;
    }
    this.set(name, "", {
      "max-age": -1,
      domain,
    });
  },
};
