import { privateRoutes, Routes } from "~~/utils/routes";

export default defineNuxtRouteMiddleware(async (to, _) => {
  const magnitId = useCookie<string>(APP_COOKIE_STORAGE_KEYS.magnitIDCode);
  const incompleteProfile = useCookie<string>(
    APP_COOKIE_STORAGE_KEYS.incompleteProfile,
  );
  const useAuth = useAuthStore();

  if (!await useAuth.checkLogin()) {
    if (magnitId.value && to.path === Routes.Registration) {
      return;
    }

    if (
      privateRoutes.includes(to.path) ||
      to.path === Routes.ProfileIncomplete
    ) {
      return navigateTo(Routes.Main);
    }
  } else {
    if (incompleteProfile.value && privateRoutes.includes(to.path)) {
      return navigateTo(Routes.ProfileIncomplete);
    }

    if (to.path === Routes.Registration) {
      return navigateTo(Routes.Profile);
    }
  }
});
