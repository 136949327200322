import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "MySettings:View": {
    ya: {
      eventName: "sett_setMain_view",
      chapter: "sett",
      page: "setMain",
    },
  },
  "MySettings:Scroll": {
    ya: {
      eventName: "sett_setMain_scroll",
      chapter: "sett",
      page: "setMain",
      scroll_percent: "",
      scroll_line: "",
    },
  },
  "MySettings:MyCard:Click": {
    ya: {
      eventName: "sett_setMain_myCard_click",
      chapter: "sett",
      page: "setMain",
      block: "myCard",
    },
  },
  "MySettings:MyProfile:Click": {
    ya: {
      eventName: "sett_setMain_myProfile_click",
      chapter: "sett",
      page: "setMain",
      block: "myProfile",
    },
  },
  "MySettings:Balance:View": {
    ya: {
      eventName: "sett_setMain_balance_view",
      chapter: "sett",
      page: "setMain",
      block: "balance",
      value_bonus: "",
      value_magnet: "",
    },
  },
  "MySettings:Balance:Bonus:Click": {
    ya: {
      eventName: "sett_setMain_balance_bonus_click",
      chapter: "sett",
      page: "setMain",
      block: "balance",
      element: "bonus",
      value_bonus: "",
    },
  },
  "MySettings:Balance:Magnet:Click": {
    ya: {
      eventName: "sett_setMain_balance_magnet_click",
      chapter: "sett",
      page: "setMain",
      block: "balance",
      element: "magnet",
      value_magnet: "",
    },
  },
  "MySettings:Personal:View": {
    ya: {
      eventName: "sett_setMain_personal_view",
      chapter: "sett",
      page: "setMain",
      block: "personal",
    },
  },
  "MySettings:Personal:Name:Click": {
    ya: {
      eventName: "sett_setMain_personal_name_click",
      chapter: "sett",
      page: "setMain",
      block: "personal",
      element: "name",
    },
  },
  "MySettings:Personal:Name:Ok:Click": {
    ya: {
      eventName: "sett_checkName_ok_click",
      chapter: "sett",
      page: "checkName",
      element: "ok",
    },
  },
  "MySettings:Personal:Name:Close:Click": {
    ya: {
      eventName: "sett_checkName_close_click",
      chapter: "sett",
      page: "checkName",
      element: "close",
    },
  },
  "MySettings:Personal:Email:Click": {
    ya: {
      eventName: "sett_setMain_personal_email_click",
      chapter: "sett",
      page: "setMain",
      block: "personal",
      element: "email",
    },
  },
  "MySettings:Personal:Email:Ok:Click": {
    ya: {
      eventName: "sett_checkEmail_ok_click",
      chapter: "sett",
      page: "checkEmail",
      element: "ok",
      block_type: "",
      change_email: "",
    },
  },
  "MySettings:Personal:Email:Close:Click": {
    ya: {
      eventName: "sett_checkEmail_close_click",
      chapter: "sett",
      page: "checkEmail",
      element: "close",
      block_type: "",
    },
  },
  "MySettings:Personal:CheckOnline:View": {
    ya: {
      eventName: "sett_setMain_personal_checkOnline_view",
      chapter: "sett",
      page: "setMain",
      block: "personal",
      element: "checkOnline",
      permission: "",
    },
  },
  "MySettings:Personal:CheckOnline:Click": {
    ya: {
      eventName: "sett_setMain_personal_checkOnline_click",
      chapter: "sett",
      page: "setMain",
      block: "personal",
      element: "checkOnline",
      turn_on: "",
    },
  },
  "MySettings:Personal:Email:Modal:Success:View": {
    ya: {
      eventName: "sett_checkEmail_success_view",
      chapter: "sett",
      page: "checkEmail",
      block: "success",
      block_type: "",
    },
  },
  "MySettings:Personal:Email:Modal:Success:Ok:Click": {
    ya: {
      eventName: "sett_checkEmail_success_ok_click",
      chapter: "sett",
      page: "checkEmail",
      block: "success",
      element: "ok",
      block_type: "",
    },
  },
  "MySettings:Personal:Email:Modal:Error:View": {
    ya: {
      eventName: "sett_checkEmail_error_view",
      chapter: "sett",
      page: "checkEmail",
      block: "error",
      block_type: "",
    },
  },
  "MySettings:Personal:Email:Modal:Error:Ok:Click": {
    ya: {
      eventName: "sett_checkEmail_error_ok_click",
      chapter: "sett",
      page: "checkEmail",
      block: "error",
      element: "ok",
      block_type: "",
    },
  },
  "MySettings:Personal:NoEmail:View": {
    ya: {
      eventName: "sett_setMain_noEmail_view",
      chapter: "sett",
      page: "setMain",
      block: "noEmail",
    },
  },
  "MySettings:Personal:Email:Confirm:View": {
    ya: {
      eventName: "sett_checkEmail_confirmEmail_view",
      chapter: "sett",
      page: "checkEmail",
      block: "confirmEmail",
    },
  },
  "MySettings:Personal:Email:Confirm:Ok:Click": {
    ya: {
      eventName: "sett_checkEmail_confirmEmail_ok_click",
      chapter: "sett",
      page: "checkEmail",
      block: "confirmEmail",
      element: "ok",
    },
  },
  "MySettings:Personal:Email:Confirm:Cancel:Click": {
    ya: {
      eventName: "sett_checkEmail_confirmEmail_back_click",
      chapter: "sett",
      page: "checkEmail",
      block: "confirmEmail",
      element: "back",
    },
  },
  "MySettings:Personal:Notices:View": {
    ya: {
      eventName: "sett_setMain_notice_view",
      chapter: "sett",
      page: "setMain",
      block: "notice",
    },
  },
  "MySettings:Personal:Notices:SMS:Click": {
    ya: {
      eventName: "sett_setMain_notice_sms_click",
      chapter: "sett",
      page: "setMain",
      block: "notice",
      element: "sms",
      turn_on: "",
    },
  },
  "MySettings:Personal:Notices:Email:Click": {
    ya: {
      eventName: "sett_setMain_notice_email_click",
      chapter: "sett",
      page: "setMain",
      block: "notice",
      element: "email",
      turn_on: "",
    },
  },
  "MySettings:Personal:Feedback:Number:View": {
    ya: {
      eventName: "sett_setMain_feedback_number_view",
      chapter: "sett",
      page: "setMain",
      block: "feedback",
      element: "number",
    },
  },
  "MySettings:Personal:Feedback:Number:Click": {
    ya: {
      eventName: "sett_setMain_feedback_number_click",
      chapter: "sett",
      page: "setMain",
      block: "feedback",
      element: "number",
    },
  },
  "MySettings:Personal:Feedback:Email:View": {
    ya: {
      eventName: "sett_setMain_feedback_email_view",
      chapter: "sett",
      page: "setMain",
      block: "feedback",
      element: "email",
    },
  },
  "MySettings:Personal:Feedback:Email:Click": {
    ya: {
      eventName: "sett_setMain_feedback_email_click",
      chapter: "sett",
      page: "setMain",
      block: "feedback",
      element: "email",
    },
  },
  "MySettings:Personal:Logout:Btn:View": {
    ya: {
      eventName: "sett_setMain_btnLogOut_view",
      chapter: "sett",
      page: "setMain",
      block: "btnLogOut",
    },
  },
  "MySettings:Personal:Logout:Btn:Click": {
    ya: {
      eventName: "sett_setMain_btnLogOut_click",
      chapter: "sett",
      page: "setMain",
      block: "btnLogOut",
    },
  },
  "MySettings:Personal:Logout:View": {
    ya: {
      eventName: "sett_setMain_LogOut_view",
      chapter: "sett",
      page: "setMain",
      block: "LogOut",
    },
  },
  "MySettings:Personal:Logout:Ok:Click": {
    ya: {
      eventName: "sett_setMain_LogOut_ok_click",
      chapter: "sett",
      page: "setMain",
      block: "LogOut",
      element: "ok",
    },
  },
  "MySettings:Personal:Logout:Cancel:Click": {
    ya: {
      eventName: "sett_setMain_LogOut_back_click",
      chapter: "sett",
      page: "setMain",
      block: "LogOut",
      element: "back",
    },
  },
  "MySettings:Personal:Delete:Btn:Click": {
    ya: {
      eventName: "sett_setMain_deleteAcc_click",
      chapter: "sett",
      page: "setMain",
      block: "deleteAcc",
    },
  },
  "MySettings:Personal:Delete:Ok:Click": {
    ya: {
      eventName: "sett_setMain_deleteAcc_confirm_click",
      chapter: "sett",
      page: "setMain",
      block: "deleteAcc",
      element: "confirm",
    },
  },
  "MySettings:Personal:Delete:Cancel:Click": {
    ya: {
      eventName: "sett_setMain_deleteAcc_back_click",
      chapter: "sett",
      page: "setMain",
      block: "deleteAcc",
      element: "back",
    },
  },
  "MySettings:Cards:View": {
    ya: {
      eventName: "sett_cards_view",
      chapter: "sett",
      page: "cards",
    },
  },
  "MySettings:Cards:Feedback:View": {
    ya: {
      eventName: "sett_cards_feedback_number_view",
      chapter: "sett",
      page: "cards",
      block: "feedback",
      element: "number",
    },
  },
  "MySettings:Cards:Feedback:Number:Click": {
    ya: {
      eventName: "sett_cards_feedback_number_click",
      chapter: "sett",
      page: "cards",
      block: "feedback",
      element: "number",
    },
  },
  "MySettings:Cards:Add:Click": {
    ya: {
      eventName: "sett_cards_addCard_click",
      chapter: "sett",
      page: "cards",
      element: "addCard",
    },
  },
  "MySettings:Cards:Info:Click": {
    ya: {
      eventName: "sett_cards_info_click",
      chapter: "sett",
      page: "cards",
      element: "info",
    },
  },
  "MySettings:Cards:Info:View": {
    ya: {
      eventName: "sett_cards_info_view",
      chapter: "sett",
      page: "cards",
      block: "info",
    },
  },
  "MySettings:Cards:Info:Back:Click": {
    ya: {
      eventName: "sett_cards_info_back_click",
      chapter: "sett",
      page: "cards",
      block: "info",
      element: "back",
    },
  },
  "MyBonuses:View": {
    ya: {
      eventName: "sett_bonusMain_bonuses_view",
      chapter: "sett",
      page: "bonusMain",
      block: "bonuses",
      value_bonus: "",
      value_express: "",
    },
  },
  "MyBonuses:Back:Click": {
    ya: {
      eventName: "sett_bonusMain_back_click",
      chapter: "sett",
      page: "bonusMain",
      element: "back",
    },
  },
  "MyBonuses:ErrorGet:View": {
    ya: {
      eventName: "sett_errorBonus_view",
      chapter: "sett",
      block: "errorBonus",
      type_error: "",
    },
  },
  "MyStickers:View": {
    ya: {
      eventName: "sett_magnets_view",
      chapter: "sett",
      page: "magnets",
      magnet_balance: "",
      expiring_magnets: "",
      expirationDate: "",
    },
  },
  "MyStickers:Back:Click": {
    ya: {
      eventName: "sett_magnets_back_click",
      chapter: "sett",
      page: "magnets",
      element: "back",
    },
  },
  "ProfileIncompletePage:View": {
    ya: {
      eventName: "incomplete_personal_view",
      chapter: "incomplete",
      page: "personal",
    },
  },
  "ProfileIncompletePage:Name:Click": {
    ya: {
      eventName: "incomplete_personal_name_click",
      chapter: "incomplete",
      page: "personal",
      block: "name",
    },
  },
  "ProfileIncompletePage:Birthday:Click": {
    ya: {
      eventName: "incomplete_personal_birth_click",
      chapter: "incomplete",
      page: "personal",
      block: "birth",
    },
  },
  "ProfileIncompletePage:Email:Click": {
    ya: {
      eventName: "incomplete_personal_email_click",
      chapter: "incomplete",
      page: "personal",
      block: "email",
    },
  },
  "ProfileIncompletePage:Subscribe:SMS:Click": {
    ya: {
      eventName: "incomplete_personal_notice_sms_click",
      chapter: "incomplete",
      page: "personal",
      block: "notice",
      element: "sms",
    },
  },
  "ProfileIncompletePage:Submit": {
    ya: {
      eventName: "incomplete_personal_continue_click",
      chapter: "incomplete",
      page: "personal",
      block: "continue",
    },
  },
  "ProfileIncompletePage:Conditions:Click": {
    ya: {
      eventName: "incomplete_personal_infoPersonal_click",
      chapter: "incomplete",
      page: "personal",
      element: "infoPersonal",
    },
  },
  "ProfileIncompletePage:Rules:Click": {
    ya: {
      eventName: "incomplete_personal_infoRules_click",
      chapter: "incomplete",
      page: "personal",
      element: "infoRules",
    },
  },
  "ProfileIncompletePage:Success": {
    ya: {
      eventName: "incomplete_success",
    },
  },
};
