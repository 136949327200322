import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Catalog:List:View": {
    ya: {
      eventName: "catalog_list_view",
      page: "catalog",
      element: "list",
      sort: "",
      categoryName: "",
      pageNumber: "",
      list_id: "",
    },
  },
  "Catalog:Item:Click": {
    ya: {
      eventName: "catalog_item_click",
      page: "catalog",
      element: "item",
      pageNumber: "",
      sort: "",
      categoryName: "",
      name: "",
      product_id: "",
      price: "",
    },
  },
  "Catalog:Navigation:Slider:View": {
    ya: {
      eventName: "catalog_navigation_view",
      page: "catalog",
      block: "navigation",
    },
  },
  "Catalog:Navigation:Slider:Item:View": {
    ya: {
      eventName: "catalog_navigation_item_view",
      page: "catalog",
      block: "navigation",
      element: "item",
      category_name: "",
      banner_name: "",
      position: "",
    },
  },
  "Catalog:Navigation:Slider:Item:Click": {
    ya: {
      eventName: "catalog_navigation_item_click",
      page: "catalog",
      block: "navigation",
      element: "item",
      category_name: "",
      banner_name: "",
      position: "",
    },
  },
  "Catalog:Navigation:Slider:Scroll": {
    ya: {
      eventName: "catalog_navigation_scroll",
      page: "catalog",
      block: "navigation",
      number: "",
      items_count: "",
    },
  },
  "Catalog:Pagination:LoadMore:View": {
    ya: {
      eventName: "catalog_more_view",
      page: "catalog",
      element: "more",
    },
  },
  "Catalog:Pagination:LoadMore:Click": {
    ya: {
      eventName: "catalog_more_click",
      page: "catalog",
      element: "more",
    },
  },
  "Catalog:YaSlot:View": {
    ya: {
      eventName: "catalog_adFox_view",
      page: "catalog",
      block: "adFox",
    },
  },
  "Catalog:YaSlot:Click": {
    ya: {
      eventName: "catalog_adFox_click",
      page: "catalog",
      block: "adFox",
      dest: "",
    },
  },
  "Catalog:YaSlot:Goods:View": {
    ya: {
      eventName: "catalog_adFox_goods_view",
      page: "catalog",
      block: "adFox_goods",
    },
  },
  "Catalog:YaSlot:Goods:Click": {
    ya: {
      eventName: "catalog_adFox_goods_click",
      page: "catalog",
      block: "adFox_goods",
      dest: "",
    },
  },
  "CatalogMenu:View": {
    ya: {
      eventName: "catalog_categList_view",
      block: "catalog",
      element: "categList",
    },
  },
  "CatalogMenu:Item:Click": {
    ya: {
      eventName: "catalog_сategList_click",
      block: "catalog",
      element: "categList",
      category_name: "",
    },
  },
};
