import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "CitySelect:ListCity:View": {
    ya: {
      eventName: "citySelect_listCity_view",
      block: "listCity",
      page: "citySelect",
    },
  },
  "CitySelect:ListCity:City:Click": {
    ya: {
      eventName: "citySelect_listCity_city_click",
      block: "listCity",
      element: "city",
      page: "citySelect",
      city: "",
      region: "",
    },
  },
  "CitySelect:SearchCity:Click": {
    ya: {
      eventName: "citySelect_searchCity_click",
      element: "searchCity",
      page: "citySelect",
      query: "",
    },
  },
  "CitySelect:ListCity:Close:Click": {
    ya: {
      eventName: "citySelect_listCity_close_click",
      block: "listCity",
      element: "close",
      page: "citySelect",
    },
  },
  "CitySelect:Error:SearchCity:View": {
    ya: {
      eventName: "citySelect_error_searchCity_view",
      block: "error",
      element: "searchCity",
      type_error: "no_cities",
      page: "citySelect",
      query: "",
    },
  },
};
