import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Login:Phone:View": {
    ya: {
      eventName: "auth_phoneNumber_view",
      chapter: "auth",
      page: "phoneNumber",
    },
  },
  "Login:Phone:Click": {
    ya: {
      eventName: "auth_phoneNumber_enterPhone_click",
      chapter: "auth",
      page: "phoneNumber",
      element: "enterPhone",
    },
  },
  "Login:Phone:Submit": {
    ya: {
      eventName: "auth_phoneNumber_continue_click",
      chapter: "auth",
      page: "phoneNumber",
      element: "continue",
    },
  },
  "Login:Phone:Close": {
    ya: {
      eventName: "auth_phoneNumber_close_click",
      chapter: "auth",
      page: "phoneNumber",
      element: "close",
    },
  },
  "Login:Phone:Captcha:Click": {
    ya: {
      eventName: "auth_phoneNumber_captcha_click",
      chapter: "auth",
      page: "phoneNumber",
      element: "captcha",
    },
  },
  "Login:OTP:View": {
    ya: {
      eventName: "auth_phoneConfirm_view",
      chapter: "auth",
      page: "phoneConfirm",
    },
  },
  "Login:OTP:Back": {
    ya: {
      eventName: "auth_phoneConfirm_back_click",
      chapter: "auth",
      page: "phoneConfirm",
      element: "back",
    },
  },
  "Login:OTP:Code:Click": {
    ya: {
      eventName: "auth_phoneConfirm_codeManual_click ",
      chapter: "auth",
      page: "phoneConfirm",
      element: "codeManual",
    },
  },
  "Login:OTP:Code:Repeat": {
    ya: {
      eventName: "auth_phoneConfirm_resubmit_click",
      chapter: "auth",
      page: "phoneConfirm",
      element: "resubmit",
    },
  },
  "Login:Success": {
    ya: {
      eventName: "auth_success_visible",
    },
  },
  "Login:OTP:Error": {
    ya: {
      eventName: "auth_phoneConfirm_error_view",
      chapter: "auth",
      page: "phoneConfirm",
      block: "error",
      type_error: "",
    },
  },
};
