import type { FetchError } from "ofetch";
import { defineStore } from "pinia";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { useTransport } from "~/composables/useTransport";
import { HttpCodes, urls } from "~/api/config";
import useNotification from "~/composables/useNotification";
import { getJWTExpiration, isJWTExpired } from "~/utils/jwt";
import { Routes } from "~/utils/routes";
import usePublicConfig from "~/composables/usePublicConfig";

interface IAuthState {
  authorized: boolean;
  registered: boolean;
  tokenValue: string | null;
  tokenExpires: number | null;
}

interface IAuthStatus {
  login: "initial" | "pending" | "success" | "error";
}

export const getHeaders = (
  headers: HeadersInit | Ref<HeadersInit | undefined> | undefined,
  version: string,
): HeadersInit => {
  const ksid = Cookie.get(APP_COOKIE_STORAGE_KEYS.ksid);
  let userAgent = "";
  let platformVersion = "omit empty";
  const deviceId =
    useCookie(APP_COOKIE_STORAGE_KEYS.uniqDeviceID).value || "omit empty";

  if (typeof window !== "undefined") {
    userAgent = window.navigator.userAgent;

    const browserInfo = getBrowserInfo();
    platformVersion = `${browserInfo.os} ${browserInfo.browser} ${browserInfo.version}`;
  } else {
    const headers = useRequestHeaders();
    userAgent = headers["user-agent"] || "";
  }

  return {
    ...toValue(headers),
    "Content-Type": "application/json",
    "User-Agent": userAgent,
    "X-Platform-Version": platformVersion,
    "X-Device-Tag": ksid || "disabled",
    "X-Device-ID": deviceId,
    "X-App-Version": version || "",
    "X-Device-Platform": "Web",
  };
};

export const useAuthStore = defineStore("auth", () => {
  const { version, cookieDomain: configCookieDomain } = usePublicConfig();
  const router = useRouter();
  const { error: showToastError } = useNotification();
  // const { send } = useAnalytics();
  const { hostname } = useRequestURL();
  const cookieDomain = configCookieDomain.value || getCookieDomain(hostname);

  const cookieJWT = useCookie<IJWT | null | undefined>(APP_COOKIE_STORAGE_KEYS.jwt, {
    domain: cookieDomain,
    maxAge: APP_COOKIE_MAX_AGE_YEAR,
    default: () => ({ access: undefined, refresh: undefined }),
    watch: true,
  });

  let refreshPromise: Promise<void> | null = null;

  const state = reactive<IAuthState>({
    authorized: false,
    registered: false,
    tokenExpires: null,
    tokenValue: null,
  });

  const fetchJWTAccessToken = async () => {
    const JWT = state.tokenValue || cookieJWT.value?.access;
    if (!JWT) return null;
    const expiration = state.tokenExpires || getJWTExpiration(JWT);
    if (expiration) {
      if (!isJWTExpired(expiration)) return JWT;
    }
    await refresh();
    return cookieJWT.value?.access;
  };

  const status = reactive<IAuthStatus>({
    login: "initial",
  });

  const getMagnitId = () =>
    Cookie.get(APP_COOKIE_STORAGE_KEYS.magnitIDCode) || "";
  const setMagnitId = (code: string) => {
    Cookie.set(APP_COOKIE_STORAGE_KEYS.magnitIDCode, code, { "max-age": 3600 });
  };

  const getRegister = () => state.registered;
  const setRegister = (value: boolean) => {
    state.registered = value;
  };

  const setTokens = (access: string, refresh: string) => {
    state.tokenValue = access;
    state.tokenExpires = getJWTExpiration(access);
    cookieJWT.value = { access, refresh };
    setAuth(true);
  };

  const setAuth = (value: boolean) => {
    state.authorized = value;
    // ToDo: наверное, добавить проверку на window
    // if (value) {
    //   send("Login:Success");
    // }
  };

  const clearCurrentTokenState = () => {
    state.tokenValue = null;
    state.tokenExpires = null;
  };

  async function logout() {
    status.login = "initial";

    setAuth(false);
    clearCurrentTokenState();

    cookieJWT.value = null;
    Cookie.delete(APP_COOKIE_STORAGE_KEYS.ksid);

    await router.push(Routes.Main);
  }

  async function login(fromRegister = false) {
    const { data, error } = await useTransport<{
      accessToken: string;
      refreshToken: string;
    }>(urls.auth.login, {
      gateway: "magnit-id",
      method: "POST",
      body: {
        aud: "loyalty-web",
        magnitIDCode: getMagnitId(),
      },
    });

    if (data.value) {
      setMagnitId("");
      setTokens(data.value.accessToken, data.value.refreshToken);

      status.login = "success";

      await router.push({
        path: Routes.Profile,
        query: fromRegister ? { [routeParams.withMergeCardModal]: "1" } : {},
      });
    }

    if (
      error.value?.statusCode &&
      error.value.statusCode >= HttpCodes.Error4xx
    ) {
      await router.push(Routes.Profile);

      status.login = "initial";
      showToastError();
    }
  }

  const checkLogin = async () => {
    if (cookieJWT.value?.access) {
      const expiration = state.tokenExpires;
      const isExpired = expiration ? isJWTExpired(expiration) : true;
      if (isExpired) {
        try {
          await refresh();
          return true;
        } catch {
          return false;
        }
      }
      state.tokenExpires = expiration;
      state.tokenValue = cookieJWT.value?.access;
      setAuth(true);
      return true;
    }
    return false;
  };

  function refresh() {
    if (!refreshPromise) {
      refreshPromise = new Promise((resolve, reject) => {
        $fetch<{
          accessToken: string;
          refreshToken: string;
        }>(urls.auth.refresh, {
          baseURL: "/magnit-id",
          headers: getHeaders({}, version.value),
          method: "POST",
          body: {
            aud: "loyalty-web",
            refreshToken: cookieJWT.value?.refresh,
          },
        }).then(
          (data) => {
            if (data.accessToken) {
              setTokens(data.accessToken, data.refreshToken);
              resolve();
            }
          },
          async (error: FetchError) => {
            if (error?.statusCode) {
              if (error.statusCode === HttpCodes.Unauthorized) {
                await logout();
                return;
              }

              if (error.statusCode >= HttpCodes.Error4xx) {
                status.login = "initial";
                showToastError();

                reject(new Error("refresh request error"));
              }
            }
          },
        );
      });
    }

    return refreshPromise.finally(() => {
      refreshPromise = null;
    });
  }

  return {
    getMagnitId,
    setMagnitId,
    getRegister,
    setRegister,
    setAuth,
    login,
    checkLogin,
    fetchJWTAccessToken,
    logout,
    refresh,
    state,
    status,
  };
});
