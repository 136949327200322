<script setup lang="ts">
import { useRouter } from "vue-router";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import { Routes } from "~/utils/routes";

const router = useRouter();
const { send } = useAnalytics();

const onGoPromoClick = () => {
  send("Error:BtnPromo:Click");
  router.push(Routes.PromoCatalog);
};

onMounted(() => {
  const visitParams = {
    NotFoundURL: { [document.location.href]: { Реферер: document.referrer } },
  };
  send("Page:Error404:View", visitParams);
});
</script>

<template>
  <NuxtLayout>
    <div class="error-page-404">
      <div class="error-page-404__content">
        <img
          class="error-page-404__image"
          src="~/assets/images/404.png"
          alt="404"
        >
        <div class="error-page-404__content_bottom">
          <VText font="title-large" class="error-page-404__text">
            Здесь ничего не нашлось
          </VText>
          <VText class="error-page-404__text">
            Зато всегда есть выгодные предложения в разделе акций
          </VText>
          <VButton
            class="error-page-404__button"
            full-width
            @click="onGoPromoClick"
          >
            Перейти в акции
          </VButton>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<style lang="postcss">
.error-page-404 {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-start;
  margin-top: var(--pl-unit-x9);

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 360px;

    &_bottom {
      padding: 0 var(--pl-unit-x5) var(--pl-unit-x4);
      text-align: center;
    }
  }

  &__button {
    margin-top: var(--pl-unit-x6);
  }

  &__text {
    display: inline-block;
    margin-bottom: var(--pl-unit-x2);
  }

  &__image {
    max-width: 100%;
    display: block;
    padding: 0 var(--pl-unit-x5);
  }

  @media (--pl-viewport-from-m) {
    &__image {
      padding: 0;
    }
  }

  @media (--pl-viewport-from-ml) {
    margin-top: 0;
    align-items: center;
  }
}
</style>
