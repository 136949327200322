import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "PromoCatalog:View": {
    ya: {
      eventName: "promo_view",
      page: "promo",
    },
  },
  "PromoCatalog:List:View": {
    ya: {
      eventName: "promo_list_view",
      page: "promo",
      element: "list",
    },
  },
  "PromoCatalog:Item:Click": {
    ya: {
      eventName: "promo_item_click",
      page: "promo",
      element: "item",
    },
  },
  "PromoCatalog:Back:Button:Click": {
    ya: {
      eventName: "promo_back_click",
      page: "promo",
      element: "back",
    },
  },
  "PromoCatalog:Navigation:Slider:View": {
    ya: {
      eventName: "promo_navigation_view",
      block: "navigation",
      page: "promo",
    },
  },
  "PromoCatalog:Navigation:Slider:Item:Click": {
    ya: {
      eventName: "promo_navigation_click",
      block: "navigation",
      page: "promo",
      element: "item",
    },
  },
  "PromoCatalog:Navigation:Slider:Item:View": {
    ya: {
      eventName: "promo_navigation_item_view",
      block: "navigation",
      page: "promo",
      element: "item",
    },
  },
  "PromoCatalog:Navigation:Slider:Scroll": {
    ya: {
      eventName: "promo_navigation_scroll",
      block: "navigation",
      page: "promo",
    },
  },
  "PromoCatalog:Pagination:LoadMore:View": {
    ya: {
      eventName: "promo_more_view",
      element: "more",
      page: "promo",
    },
  },
  "PromoCatalog:Pagination:LoadMore:Click": {
    ya: {
      eventName: "promo_more_click",
      element: "more",
      page: "promo",
    },
  },
  "PromoCatalog:YaSlot:View": {
    ya: {
      eventName: "promo_adFox_view",
      block: "adFox",
      page: "promo",
    },
  },
  "PromoCatalog:YaSlot:Click": {
    ya: {
      eventName: "promo_adFox_click",
      block: "adFox",
      page: "promo",
    },
  },
  "PromoCatalog:Categories:List:View": {
    ya: {
      eventName: "promo_categories_view",
      block: "categories",
      page: "promo",
    },
  },
  "PromoCatalog:Categories:Item:Click": {
    ya: {
      eventName: "promo_categories_click",
      block: "categories",
      page: "promo",
    },
  },
  "PromoCatalog:YaSlot:Goods:View": {
    ya: {
      eventName: "promo_adFox_goods_view",
      page: "promo",
      block: "adFox_goods",
    },
  },
  "PromoCatalog:YaSlot:Goods:Click": {
    ya: {
      eventName: "promo_adFox_goods_click",
      page: "promo",
      block: "adFox_goods",
      dest: "",
    },
  },
};
