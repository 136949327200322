import { cookiePolicyRoute } from "@magnit/unit-cookie-policy/src/cookie-policy.vars";

export enum Routes {
  PromoCatalog = "/promo-catalog",
  PromoProduct = "/promo-product",
  Catalog = "/catalog",
  Product = "/product",
  Main = "/",
  Profile = "/profile",
  ProfileBonuses = "/profile/bonuses",
  ProfileStickers = "/profile/stickers",
  ProfileIncomplete = "/profile/incomplete",
  Registration = "/registration",
  RegistrationCard = "/registration/card",
  CookiePolicy = `${cookiePolicyRoute}`,
  Search = "/search",
  Articles = "/articles",
}

export enum RouteNames {
  PromoCatalog = "promo-catalog",
  Catalog = "catalog",
  CatalogCategory = "catalog-category",
  CatalogCategorySlug = "catalog-category-slug",
  ProductGoods = "product-product-slug",
}

export const privateRoutes: string[] = [
  Routes.Profile,
  Routes.ProfileBonuses,
  Routes.ProfileStickers,
  Routes.RegistrationCard,
];

export const routeParams = {
  firstAddCard: "firstAddCard",
  withMergeCardModal: "withMergeCardModal",
  sortBy: "sortBy",
  order: "order",
  tab: "tab",
  type: "type",
  page: "page",
  category: "category",
} as const;
