import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";
import {
  catalogListingEvents,
  modalAdultEvents,
} from "@magnit/unit-catalog/src/unit-catalog.events";
import eventsMain from "./main";
import eventsCardDetail from "./cardDetail";
import eventsCitySelect from "./citySelect";
import eventsStoreSelect from "./storeSelect";
import eventsToolbar from "./toolbar";
import eventsLogin from "./login";
import eventsRegistration from "./registration";
import eventsAddCard from "./addCard";
import eventsProfile from "./profile";
import eventsError from "./error";
import eventsPromoCatalog from "./promoCatalog";
import eventsHeaderSearch from "./headerSearch";
import eventsNavigation from "./navigation";
import eventsArticle from "./article";
import eventsCatalog from "./catalog";
import eventsHeader from "./header";

export default <IAnalyticEventsConfig>{
  ...eventsToolbar,
  ...eventsCitySelect,
  ...eventsStoreSelect,
  ...eventsLogin,
  ...eventsError,
  ...eventsHeaderSearch,
  ...eventsNavigation,
  ...eventsMain,
  ...eventsCardDetail,
  ...eventsRegistration,
  ...eventsProfile,
  ...modalAdultEvents,
  ...eventsPromoCatalog,
  ...eventsCatalog,
  ...catalogListingEvents,
  ...eventsAddCard,
  ...eventsArticle,
  ...eventsHeader,
};
